import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styledComponents'

import {
  CardSequenceContainer,
  CardStackContainer,
  ConversationsContainer,
  HeaderContainer,
  FooterContainer
} from 'components'
import { Container } from 'components/_utility'
import { shadow } from 'style/mixins/'
import { sizes } from 'style/variables'

import {
  BEHAVIOR_IFRAME_ID,
  LAUNCHER_IFRAME_ID,
  MAX_WINDOW_HEIGHT,
  MIN_WINDOW_HEIGHT,
  VIEWS_IFRAME_ID
} from 'common/constants'
import {
  CardSequenceViewInterface,
  CardStackViewInterface,
  ConversationsViewInterface,
  NavButtonInterface
} from 'types'

export interface Props {
  className?: string
  open: boolean
  view:
    | CardSequenceViewInterface
    | CardStackViewInterface
    | ConversationsViewInterface
  navButtons: NavButtonInterface[]
  previewMode: string
  webMoveUp: number
  mobileMoveUp: number
  nav: boolean
}

const Component = ({
  className,
  view,
  open,
  navButtons,
  previewMode,
  webMoveUp
}: Props) => {
  const resizeHandler = () => {
    const el = document.getElementById('rp-window')
    const computedHeight = window.top.innerHeight - 72 - 36 - 9 - webMoveUp
    if (el instanceof HTMLElement) {
      const singleButtonNavAdjustment = navButtons.length ? 0 : 36
      const newHeight =
        window.top.innerWidth > sizes.mobileWidth
          ? `${computedHeight}px`
          : `${window.top.innerHeight -
              72 -
              singleButtonNavAdjustment -
              webMoveUp}px`
      el.style.height = newHeight
      const cardCount = 'cardIds' in view ? view.cardIds.length : 0
      const ratio = cardCount === 1 ? 0.6 : cardCount === 2 ? 0.8 : 1
      el.style.maxHeight = `${(computedHeight < MAX_WINDOW_HEIGHT - webMoveUp
        ? computedHeight
        : MAX_WINDOW_HEIGHT - webMoveUp) * ratio}px`
      el.style.minHeight = `${MIN_WINDOW_HEIGHT - webMoveUp}px`
    }
  }

  if (previewMode === 'none') {
    React.useEffect(() => {
      window.top.addEventListener('resize', resizeHandler)
      return () => window.top.removeEventListener('resize', resizeHandler)
    }, [])
  }

  const viewRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if ((view as CardSequenceViewInterface | CardStackViewInterface)._id) {
      viewRef.current!.scrollTop = 0
    }
  }, [(view as CardSequenceViewInterface | CardStackViewInterface)._id])

  React.useEffect(() => {
    const viewIframe = window.parent.document.getElementById(VIEWS_IFRAME_ID)
    const behaviorIframe = window.parent.document.getElementById(
      BEHAVIOR_IFRAME_ID
    )
    const launcherIframe = window.parent.document.getElementById(
      LAUNCHER_IFRAME_ID
    )
    const cardCount = 'cardIds' in view ? view.cardIds.length : 0
    const maxHeight =
      cardCount === 1 ? '500px' : cardCount === 2 ? '650px' : '825px'

    if (viewIframe && viewIframe.style) viewIframe.style.maxHeight = maxHeight
    if (behaviorIframe && behaviorIframe.style)
      behaviorIframe.style.maxHeight = maxHeight
    if (launcherIframe && launcherIframe.style)
      launcherIframe.style.maxHeight = maxHeight
    if (previewMode === 'none') {
      resizeHandler()
    }
  }, [view])

  return (
    <Container
      _id="rp-window"
      className={className}
      mb={9}
      display={open ? 'block' : 'none'}
    >
      {view && (
        <Background
          template={view.template}
          cardCount={'cardIds' in view ? view.cardIds.length : 0}
        />
      )}
      {view && (
        <ViewWrapper
          _id="rp-view-wrapper"
          previewMode={previewMode}
          ref={viewRef}
        >
          <HeaderContainer header={view.header} />
          {view.template === 'cardStack' && (
            <CardStackContainer view={view as CardStackViewInterface} />
          )}
          {view.template === 'cardSequence' && (
            <CSSTransition
              classNames="view"
              appear={true}
              in={true}
              timeout={0}
            >
              <CardSequenceContainer view={view as CardSequenceViewInterface} />
            </CSSTransition>
          )}
          {view.template === 'conversations' && <ConversationsContainer />}
          <FooterContainer
            isConversations={view.header.navigation === 'Messenger'}
            footer={view.header.footer /* TODO: Not this */}
          />
        </ViewWrapper>
      )}
    </Container>
  )
}

// @ts-ignore
export const WindowDisplay = styled(Component)`
  position: relative;
  z-index: 0;
  flex-grow: 1;
  width: 100%;
  ${p =>
    p.previewMode === 'none' &&
    css`
      height: ${window.top.innerHeight - 72 - 36 - 30 - 9 - p.webMoveUp}px;
      min-height: ${MIN_WINDOW_HEIGHT - p.webMoveUp}px;
      max-height: ${window.top.innerHeight - 72 - 36 - 30 - 9 - p.webMoveUp <
      MAX_WINDOW_HEIGHT - p.webMoveUp
        ? window.top.innerHeight - 72 - 36 - 9 - p.webMoveUp
        : MAX_WINDOW_HEIGHT - p.webMoveUp}px;
    `}

  /* height: calc(100vh - 117px); */

  border-radius: 12px;
  overflow: ${window.navigator.userAgent.indexOf('Edge') > -1
    ? 'auto'
    : 'hidden'};
  background-color: ${p =>
    p.theme.isCadillacTheme
      ? p.theme.cadilacBackGroundColor
      : p.theme.colors.lightest};
  ${shadow('spread') as any}

  ${p =>
    sizes.isMobile &&
    css`
      height: ${window.top.innerHeight - (p.nav ? 72 : 108) - p.mobileMoveUp}px;
      min-height: ${window.top.innerHeight -
        (p.nav ? 72 : 108) -
        p.mobileMoveUp}px;
      max-height: ${window.top.innerHeight -
        (p.nav ? 72 : 108) -
        p.mobileMoveUp}px;

      border-radius: 0;
      margin-bottom: 0;
    `}

  .view-appear {
    opacity: 0;
  }

  .view-enter-done {
    transition: opacity 270ms;
    opacity: 1;
  }
`

const Background = styled('div')<{ template: string; cardCount: number }>`
  position: absolute;
  z-index: -1;
  width: 100%;

  background-repeat: no-repeat;

  transition: height 0.4s, opacity 0.4s ease-out;

  ${p => {
    return css`
      background: ${p.theme.isCadillacTheme != true
        ? p.theme.gradient
          ? p.cardCount === 1
            ? `linear-gradient(
        ${p.theme.colors.original},
        ${p.theme.colors.light},
        ${p.theme.colors.lightest}
      )`
            : `linear-gradient(
        ${p.theme.colors.original},
        ${p.theme.colors.light},
        ${p.theme.colors.lightest},
        ${p.theme.colors.lightest}
      )`
          : p.theme.colors.original
        : p.theme.cadilacBackGroundColor};

      ${p.template !== 'conversations' &&
        `
        height: 100%;
        opacity: 1;
      `}

      ${p.template === 'conversations' &&
        `
        height: 48px;
        opacity: 0.5;
        ${shadow('medium')}
      `}
    `
  }}
`

// @ts-ignore
const ViewWrapper = styled(Container)<{ previewMode: string }>`
  height: calc(100% - 48px);
  margin-top: 48px;
  overflow: auto;
  line-height: normal;
`
