import * as React from 'react'
import { Omit } from 'utility-types'

import { NAVIGATION } from 'common/constants'
import { Button } from 'components/_utility'
import { trackCustomEvent } from 'trackCustomEvent'
import { ButtonProps, HelpersProps, TrackedEventDestinations } from 'types'

export interface ActionButtonActionInterface {
  label: string
  type: string
  destination?: string
  destinationProps?: { [x: string]: any }
  onClick?: () => void
  disabled?: boolean
  isToolbarModeEnabled?: boolean
}

export interface Props
  extends Omit<
    ButtonProps & HelpersProps & ActionButtonActionInterface,
    'children'
  > {
  navigate: (viewId: string, params?: { [x: string]: any }) => void
}

export const ActionButtonDisplay = ({
  type,
  destination,
  destinationProps,
  label,
  navigate,
  onClick,
  isToolbarModeEnabled,
  ...props
}: Props) => {
  const trackClick = () => {
    trackCustomEvent('CTA_CLICK', { label }, [
      TrackedEventDestinations.MIXPANEL
    ])
  }

  const customAttributes = {
    ...(label.length > 30 && { title: label })
  }

  let hrefDestination = destination || ''
  let hrefTag = (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={hrefDestination}
      onClick={trackClick}
    >
      <Button customAttributes={customAttributes} {...props}>
        {label}
      </Button>
    </a>
  )
  if (type === 'href') {
    if (hrefDestination.match(/^tel:/g) || hrefDestination.match(/^mailto:/g)) {
      hrefTag = (
        <a
          target="_parent"
          rel="noopener noreferrer"
          href={hrefDestination}
          onClick={trackClick}
        >
          <Button customAttributes={customAttributes} {...props}>
            {label}
          </Button>
        </a>
      )
    } else if (!hrefDestination.match(/^https?:\/\//g)) {
      hrefDestination = `https://${hrefDestination}`
    }
  }

  const runEmbedCode = () => {
    try {
      if (!destination) return
      else {
        new Function(destination)()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      {type === 'view' && destination && (
        <Button
          customAttributes={customAttributes}
          onClick={() => {
            trackClick()
            navigate(destination, destinationProps)
          }}
          {...props}
        >
          {label}
        </Button>
      )}
      {type === 'bot' && !isToolbarModeEnabled && (
        <Button
          customAttributes={customAttributes}
          onClick={() => {
            trackClick()
            navigate(NAVIGATION.CHATBOT, { chatbotId: destination })
          }}
          {...props}
        >
          {label}
        </Button>
      )}
      {type === 'href' && hrefTag}
      {type === 'custom' && (
        <Button
          customAttributes={customAttributes}
          onClick={onClick}
          {...props}
        >
          {label}
        </Button>
      )}
      {type === 'embed_code' && (
        <Button
          customAttributes={customAttributes}
          {...props}
          onClick={runEmbedCode}
        >
          {label}
        </Button>
      )}
    </React.Fragment>
  )
}
