export const ENTER_KEY_CODE = 13

export const EXTRA_FIELDS_KEY = 'extraFields'

export const FORM_CARD_ID_SEPARATOR = '_ca_'

export const STOP_TYPING_TIMEOUT = 5000

export const BOT_STOP_TYPING_TIMEOUT = 2000

export const MAX_WINDOW_HEIGHT = 705

export const MIN_WINDOW_HEIGHT = 252

export const VIEWS_IFRAME_ID = 'viewsView_ep2iFrame'
export const LAUNCHER_IFRAME_ID = 'launcherView_ep2iFrame'
export const BEHAVIOR_IFRAME_ID = 'behaviorView_ep2iFrame'

export const NAVIGATION = {
  CONVERSATIONS: '__conversations__',
  CHATBOT: '__chatbot__',
  FAQS: '__faqs__',
  BOOKING: '__booking__'
}

export const VIRTUAL_CARDS = {
  CHATBOT: {
    _id: 'chatbot_card',
    template: 'chatbot',
    header: { title: 'Chat With Us', alwaysShow: false },
    props: {}
  },
  MESSAGES: {
    _id: 'messages_card',
    template: 'messages',
    header: { title: 'Your Messages', alwaysShow: false },
    props: {}
  },
  FAQS: {
    _id: 'faqs_card',
    template: 'faqs',
    header: { title: 'FAQs', alwaysShow: false },
    props: {}
  },
  BOOKING: {
    _id: 'appointment_card',
    template: 'appointment',
    header: { title: 'Book an Appointment', alwaysShow: false },
    props: {
      index: 0,
      datePickerIndex: 0
    }
  }
}

export const CHATBOT_FORM_MAIN_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'vehicleMake',
  'vehicleModel',
  'vehicleYear',
  'vehicleMakeDropdown',
  'vehicleModelDropdown',
  'vehicleYearDropdown',
  'vehicleOpenSearch',
  'address',
  'city',
  'state',
  'zipCode'
]

export const TERMS_AND_CONDITIONS_LINK = 'https://digitalairstrike.com/privacy/'

export const CATCH_ALL_FORM_ID = 'catch-all'

export const REVIEWS_SORT_OPTIONS = [
  { value: 'date:desc', label: 'Newest' },
  { value: 'date:asc', label: 'Oldest' },
  { value: 'rating:desc', label: 'Highest Rated' },
  { value: 'rating:asc', label: 'Lowest Rated' }
]
